<template>
  <div class="d-flex justify-content-between new-block">
    <div>
      <h2 class="float-start">
        {{
          $t(
            "ViewFilters.EditViewFilter",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}
      </h2>
    </div>
    <div>
      <ActionButtons
        v-if="getViewFilterRequestFinished"
        @methodSave="onSubmit('btn-save')"
        :isNewRecord="false"
      />
    </div>
  </div>
  <SetLoader v-show="!getViewFilterRequestFinished" />
  <div v-if="errors.length > 0" class="alert alert-warning mt-2 mb-0">
    <ul class="mb-0">
      <li v-for="error in errors" v-bind:key="error">
        {{ error }}
      </li>
    </ul>
  </div>
  <form
    class="form-viewfilter-edit pe-2 ps-2"
    v-show="getViewFilterRequestFinished && viewFilterData.isOk"
    novalidate
  >
    <ul class="nav nav-tabs mb-2 mt-0" role="tablist">
      <li class="nav-item" role="presentation">
        <button
          class="nav-link active"
          id="pills-general-tab"
          data-bs-toggle="pill"
          @click="tabValidation"
          data-bs-target="#pills-general"
          type="button"
          role="tab"
          aria-selected="true"
        >
          <i class="bi bi-gear-fill"></i>
          {{
            $t(
              "ViewFilters.GeneralSettings",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          v-if="
            !String.isNullOrWhiteSpace(viewFilterData.name) &&
            !String.isNullOrWhiteSpace(viewFilterData.formulaName)
          "
          @click="tabValidation"
          class="nav-link"
          id="pills-criteria-tab"
          data-bs-toggle="pill"
          data-bs-target="#pills-criteria"
          type="button"
          role="tab"
          aria-selected="false"
        >
          <i class="bi bi-funnel-fill"></i>
          {{
            $t(
              "ViewFilters.Criterias",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </button>
        <button type="button" class="nav-link" @click="tabValidation" v-else>
          <i class="bi bi-funnel-fill"></i>
          {{
            $t(
              "ViewFilters.Criterias",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          v-if="
            !String.isNullOrWhiteSpace(viewFilterData.name) &&
            !String.isNullOrWhiteSpace(viewFilterData.formulaName)
          "
          @click="tabValidation"
          class="nav-link"
          id="pills-columns-tab"
          data-bs-toggle="pill"
          data-bs-target="#pills-columns"
          type="button"
          role="tab"
          aria-selected="false"
        >
          <i class="bi bi-table"></i>
          {{
            $t(
              "ViewFilters.Columns",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </button>
        <button type="button" class="nav-link" @click="tabValidation" v-else>
          <i class="bi bi-table"></i>
          {{
            $t(
              "ViewFilters.Columns",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          v-if="
            !String.isNullOrWhiteSpace(viewFilterData.name) &&
            !String.isNullOrWhiteSpace(viewFilterData.formulaName)
          "
          @click="tabValidation"
          class="nav-link"
          id="pills-orders-tab"
          data-bs-toggle="pill"
          data-bs-target="#pills-orders"
          type="button"
          role="tab"
          aria-selected="false"
        >
          <i class="bi bi-sort-alpha-down"></i>
          {{
            $t(
              "ViewFilters.Orders",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </button>
        <button type="button" class="nav-link" @click="tabValidation" v-else>
          <i class="bi bi-sort-alpha-down"></i>
          {{
            $t(
              "ViewFilters.Orders",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          v-if="
            !String.isNullOrWhiteSpace(viewFilterData.name) &&
            !String.isNullOrWhiteSpace(viewFilterData.formulaName)
          "
          @click="tabValidation"
          class="nav-link"
          id="pills-totals-tab"
          data-bs-toggle="pill"
          data-bs-target="#pills-totals"
          type="button"
          role="tab"
          aria-selected="false"
        >
          <i class="bi bi-key"></i>
          {{
            $t(
              "ViewFilters.Totals",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </button>
        <button type="button" class="nav-link" @click="tabValidation" v-else>
          <i class="bi bi-key"></i>
          {{
            $t(
              "ViewFilters.Totals",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          v-if="
            !String.isNullOrWhiteSpace(viewFilterData.name) &&
            !String.isNullOrWhiteSpace(viewFilterData.formulaName)
          "
          @click="tabValidation"
          class="nav-link"
          id="pills-buttons-tab"
          data-bs-toggle="pill"
          data-bs-target="#pills-buttons"
          type="button"
          role="tab"
          aria-selected="false"
        >
          <i class="bi bi-app"></i>
          {{
            $t(
              "ViewFilters.Buttons",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </button>
        <button type="button" class="nav-link" @click="tabValidation" v-else>
          <i class="bi bi-app"></i>
          {{
            $t(
              "ViewFilters.Buttons",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </button>
      </li>
    </ul>
    <div class="tab-content">
      <div class="tab-pane fade active show" id="pills-general" role="tabpanel">
        <div class="row row-cols-1">
          <div class="col col-md-6">
            <label for="Name" class="form-label required"
              >{{
                $t(
                  "ViewFilters.Name",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </label>
            <input
              type="text"
              class="form-control"
              id="Name"
              v-model="viewFilterData.name"
              :autocomplete="this.$isAutoComplete"
              :spellcheck="this.$isTextSpellCheck"
              required
            />
          </div>
          <div class="col col-md-6">
            <label for="FormulaName" class="form-label required"
              >{{
                $t(
                  "ViewFilters.FormulaName",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </label>
            <input
              type="text"
              class="form-control"
              :value="viewFilterData.formulaName"
              disabled
              :autocomplete="this.$isAutoComplete"
              :spellcheck="this.$isTextSpellCheck"
            />
          </div>
        </div>
        <div class="row mt-3">
          <div class="col col-md-12">
            <label for="Description" class="form-label"
              >{{
                $t(
                  "ViewFilters.Description",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </label>
            <textarea
              class="form-control"
              v-model="viewFilterData.description"
              id="Description"
              rows="1"
            ></textarea>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col">
            <div class="form-check">
              <label for="IsActive" class="form-label">{{
                $t(
                  "BaseModelFields.IsActive",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</label>
              <input
                class="form-check-input"
                type="checkbox"
                id="IsActive"
                v-model="viewFilterData.isActive"
              />
            </div>
          </div>
          <div class="col">
            <div class="form-check">
              <label for="IsDefaultFilter" class="form-label">{{
                $t(
                  "ViewFilters.IsDefaultFilter",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</label>
              <input
                class="form-check-input"
                type="checkbox"
                id="IsDefaultFilter"
                v-model="viewFilterData.isDefaultFilter"
              />
            </div>
          </div>
          <div class="col">
            <div class="form-check">
              <label for="IsSystemFilter" class="form-label">{{
                $t(
                  "ViewFilters.IsSystemFilter",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</label>
              <input
                class="form-check-input"
                type="checkbox"
                id="IsSystemFilter"
                v-model="viewFilterData.isSystemFilter"
              />
            </div>
          </div>
          <div class="col">
            <div class="form-check">
              <label for="IsCellEditingEnabled" class="form-label">{{
                $t(
                  "ViewFilters.IsCellEditingEnabled",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</label>
              <input
                class="form-check-input"
                type="checkbox"
                id="IsCellEditingEnabled"
                v-model="viewFilterData.isCellEditingEnabled"
              />
            </div>
          </div>
        </div>
        <div class="card mt-3">
          <div class="card-body">
            <h3 class="card-title">
              <i class="bi bi-sliders"></i>
              {{
                $t(
                  "ViewFilters.RelationDetail",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </h3>
            <div class="row mt-3">
              <div class="col-md-auto col-sm-12">
                <div class="form-check">
                  <label for="IsRelationDetail" class="form-label">{{
                    $t(
                      "ViewFilters.IsRelationDetail",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</label>
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="IsRelationDetail"
                    v-model="viewFilterData.isRelationDetail"
                  />
                </div>
              </div>
              <div class="col" v-if="viewFilterData.isRelationDetail">
                <label class="form-label required">{{
                  $t(
                    "ViewFilters.LookupRelation",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <FormSelect
                  type="SelectWithRemoteDataAndInternalSearch"
                  :isDetailIconShow="true"
                  :routeLink="
                    String.format(
                      '#/CustomObject/EditObjectRelation/{0}&objectRelationId=',
                      this.$route.params.customObjectId
                    )
                  "
                  @onChange="onChangeRelationDetail"
                  :allowEmpty="false"
                  :selectedData="selectedObjectRelation"
                  :isParameters="true"
                  :isGatewayRequest="true"
                  openDirection="top"
                  :requestUrl="
                    String.format(
                      '/Lcdp-SummaryLookupRelations?coId={0}',
                      this.$route.params.customObjectId
                    )
                  "
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tab-pane fade" id="pills-criteria" role="tabpanel">
        <template v-if="queryBuilderIsVisible">
          <div class="col-lg-12">
            <QueryBuilder
              :criteria="viewFilterData.criterias"
              :criteriaExpression="viewFilterData.criteriaExpression"
              ref="sxQuerybuilder"
            />
          </div>
        </template>
      </div>
      <div
        class="tab-pane fade bg-contain mh-350"
        id="pills-columns"
        role="tabpanel"
        :style="{
          backgroundImage:
            'url(' +
            require('@/assets/images/backgrounds/viewfilter-columns.png') +
            ')',
        }"
      >
        <div class="text-center viewfilter-columnbox mh-350">
          <div class="viewfilter-columnbox-item">
            <span class="viewfilter-columnbox-item-title"
              ><i class="bi bi-table me-1"></i>
              {{
                $t(
                  "ViewFilters.Columns",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</span
            >
            <span
              class="viewfilter-columnbox-item-description mb-1 pb-0 mh-auto"
              >{{
                $t(
                  "ViewFilters.ColumnsDescription",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</span
            >
            <span class="viewfilter-columnbox-item-description">
              <div class="row">
                <div class="col-xs-12 col-md-12">
                  <button
                    type="button"
                    class="btn border btn-success w-100"
                    data-bs-toggle="modal"
                    data-bs-target="#modalColumnDesign"
                  >
                    <i class="bi bi-columns-gap"></i> &nbsp;
                    {{
                      $t(
                        "ViewFilters.DesignButton",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </button>
                </div>
              </div>
            </span>
          </div>
        </div>
      </div>
      <div class="tab-pane fade" id="pills-orders" role="tabpanel">
        <table class="table table-bordered table-order table-layout-fixed">
          <thead class="bg-warning bg-gradient">
            <tr>
              <th>
                {{
                  $t(
                    "ViewFilters.OrderField",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </th>
              <th class="text-center">
                {{
                  $t(
                    "ViewFilters.OrderType",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </th>
              <th width="100" class="text-center">
                {{
                  $t(
                    "ViewFilters.Action",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>
                <FormSelect
                  type="SelectWithLocalData"
                  :resetSelect="multipleSelectValueReset"
                  :data="orderFields"
                  @onChange="onChangeForOrderField"
                />
              </th>
              <th class="text-center vertical-align-middle">
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input btn-custom-radio-size"
                    type="radio"
                    name="order"
                    id="asc"
                    value="true"
                    v-model="orderType"
                  />
                  <label class="form-check-label mt-1" for="asc">{{
                    $t(
                      "ViewFilters.Ascending",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input btn-custom-radio-size"
                    type="radio"
                    name="order"
                    id="desc"
                    value="false"
                    v-model="orderType"
                  />
                  <label class="form-check-label mt-1" for="desc">{{
                    $t(
                      "ViewFilters.Descending",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</label>
                </div>
              </th>
              <th class="text-center vertical-align-middle">
                <button
                  type="button"
                  class="btn btn-success btn-sm"
                  @click="orderFieldAdd"
                  :disabled="this.selectedOrderFields.length >= 3"
                >
                  {{
                    $t(
                      "ViewFilters.Add",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </button>
              </th>
            </tr>
            <tr v-for="item in selectedOrderFields" :key="item.field">
              <td>
                {{ item.fieldText }}
              </td>
              <td class="text-center vertical-align-middle">
                <span v-if="item.orderTypeText == 'asc'">
                  {{
                    $t(
                      "ViewFilters.Ascending",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </span>
                <span v-else>
                  {{
                    $t(
                      "ViewFilters.Descending",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </span>
              </td>
              <td class="text-center vertical-align-middle">
                <button
                  type="button"
                  class="btn btn-danger btn-sm"
                  @click="orderFieldRemove(item)"
                >
                  {{
                    $t(
                      "ViewFilters.Remove",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="tab-pane fade" id="pills-totals" role="tabpanel">
        <table class="table table-bordered table-order table-layout-fixed">
          <thead class="bg-warning bg-gradient">
            <tr>
              <th>
                {{
                  $t(
                    "ViewFilters.TotalField",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </th>
              <th>
                {{
                  $t(
                    "ViewFilters.TotalType",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </th>
              <th width="100" class="text-center">
                {{
                  $t(
                    "ViewFilters.Action",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>
                <FormSelect
                  type="SelectWithLocalData"
                  :resetSelect="multipleSelectAggregateFieldValueReset"
                  :data="aggregateFields"
                  @onChange="onChangeForAggregateField"
                />
              </th>
              <th class="text-center">
                <FormSelect
                  type="SelectWithLocalData"
                  :resetSelect="multipleSelectAggregateTypeReset"
                  :data="aggregateTypes"
                  @onChange="onChangeForAggregateType"
                />
              </th>
              <th class="text-center vertical-align-middle">
                <button
                  type="button"
                  class="btn btn-success btn-sm"
                  @click="totalFieldAdd"
                >
                  {{
                    $t(
                      "ViewFilters.Add",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </button>
              </th>
            </tr>
            <tr v-for="item in selectedAggregateFields" :key="item.field">
              <td>
                {{ item.fieldText }}
              </td>
              <td>
                {{ item.aggregateTypeText }}
              </td>
              <td class="text-center vertical-align-middle">
                <button
                  type="button"
                  class="btn btn-danger btn-sm"
                  @click="aggregateFieldRemove(item)"
                >
                  {{
                    $t(
                      "ViewFilters.Remove",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="tab-pane fade" id="pills-buttons" role="tabpanel">
        <div class="card mt-2">
          <div class="card-body">
            <h3 class="card-title">
              <i class="bi bi-sliders"></i>
              {{
                $t(
                  "ViewFilters.ActiveButtons",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </h3>
            <div class="row mt-3">
              <div class="col">
                <div class="form-check">
                  <label for="IsFilterSearchBoxActive" class="form-label">{{
                    $t(
                      "ViewFilters.FilterSearchBox",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</label>
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="IsFilterSearchBoxActive"
                    v-model="viewFilterData.isFilterSearchBoxActive"
                  />
                </div>
              </div>
              <div class="col">
                <div class="form-check">
                  <label for="IsInstantFilterActive" class="form-label">{{
                    $t(
                      "ViewFilters.InstantFilter",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</label>
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="IsInstantFilterActive"
                    v-model="viewFilterData.isInstantFilterActive"
                  />
                </div>
              </div>
              <div class="col">
                <div class="form-check">
                  <label for="IsKanbanButtonActive" class="form-label">{{
                    $t(
                      "ViewFilters.KanbanButtonGroup",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</label>
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="IsKanbanButtonActive"
                    v-model="viewFilterData.isKanbanButtonActive"
                  />
                </div>
              </div>
              <div class="col">
                <div class="form-check">
                  <label for="IsCalendarButtonActive" class="form-label">{{
                    $t(
                      "ViewFilters.CalendarButtonGroup",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</label>
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="IsCalendarButtonActive"
                    v-model="viewFilterData.isCalendarButtonActive"
                  />
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col">
                <div class="form-check">
                  <label for="IsMapButtonActive" class="form-label">{{
                    $t(
                      "ViewFilters.MapButtonGroup",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</label>
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="IsMapButtonActive"
                    v-model="viewFilterData.isMapButtonActive"
                  />
                </div>
              </div>
              <div class="col">
                <div class="form-check">
                  <label for="IsExportButtonActive" class="form-label">{{
                    $t(
                      "ViewFilters.ExportButtonGroup",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</label>
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="IsExportButtonActive"
                    v-model="viewFilterData.isExportButtonActive"
                  />
                </div>
              </div>
              <div class="col">
                <div class="form-check">
                  <label for="IsBulkProcessButtonActive" class="form-label">{{
                    $t(
                      "ViewFilters.BulkProcessButtonGroup",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</label>
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="IsBulkProcessButtonActive"
                    v-model="viewFilterData.isBulkProcessButtonActive"
                  />
                </div>
              </div>
              <div class="col">
                <div class="form-check">
                  <label for="IsNewButtonActive" class="form-label">{{
                    $t(
                      "ViewFilters.New",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</label>
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="IsNewButtonActive"
                    v-model="viewFilterData.isNewButtonActive"
                  />
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col">
                <div class="form-check">
                  <label for="IsEditInlineButtonActive" class="form-label">{{
                    $t(
                      "ViewFilters.IsEditInlineButtonActive",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</label>
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="IsEditInlineButtonActive"
                    v-model="viewFilterData.isEditInlineButtonActive"
                  />
                </div>
              </div>
              <div class="col">
                <div class="form-check">
                  <label for="IsEditInlineOn" class="form-label">{{
                    $t(
                      "ViewFilters.IsEditInlineOn",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</label>
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="IsEditInlineOn"
                    v-model="viewFilterData.isEditInlineOn"
                  />
                </div>
              </div>
              <div class="col">
                <div class="form-check">
                  <label for="IsTreeButtonActive" class="form-label">{{
                    $t(
                      "ViewFilters.TreeButtonGroup",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</label>
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="IsTreeButtonActive"
                    v-model="viewFilterData.isTreeButtonActive"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card mt-3">
          <div class="card-body">
            <h3 class="card-title">
              <i class="bi bi-sliders"></i>
              {{
                $t(
                  "ViewFilters.ActiveActionButtons",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </h3>
            <div class="row mt-3">
              <div class="col">
                <div class="form-check">
                  <label for="IsDetailButtonActive" class="form-label">{{
                    $t(
                      "ViewFilters.Detail",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</label>
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="IsDetailButtonActive"
                    v-model="viewFilterData.isDetailButtonActive"
                  />
                </div>
              </div>
              <!-- <div class="col">
                <div class="form-check">
                  <label for="IsActiveButtonActive" class="form-label">{{
                    $t(
                      "ViewFilters.Active",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</label>
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="IsActiveButtonActive"
                    v-model="viewFilterData.isActiveButtonActive"
                  />
                </div>
              </div> -->
              <div class="col">
                <div class="form-check">
                  <label for="IsDeleteButtonActive" class="form-label">{{
                    $t(
                      "ViewFilters.Delete",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</label>
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="IsDeleteButtonActive"
                    v-model="viewFilterData.isDeleteButtonActive"
                  />
                </div>
              </div>
              <div class="col">
                <div class="form-check">
                  <label for="IsEditButtonActive" class="form-label">{{
                    $t(
                      "ViewFilters.Edit",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</label>
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="IsEditButtonActive"
                    v-model="viewFilterData.isEditButtonActive"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <table class="table table-bordered table-order table-layout-fixed">
          <thead class="bg-warning bg-gradient">
            <tr>
              <th>
                {{
                  $t(
                    "ViewFilters.Button",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </th>
              <th width="100" class="text-center">
                {{
                  $t(
                    "ViewFilters.Action",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>
                <FormSelect
                  type="SelectWithLocalData"
                  :resetSelect="selectButtonReset"
                  :data="buttons"
                  @onChange="onChangeForButton"
                />
              </th>
              <th class="text-center vertical-align-middle">
                <button
                  type="button"
                  class="btn btn-success btn-sm"
                  @click="buttonAdd"
                >
                  {{
                    $t(
                      "ViewFilters.Add",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </button>
              </th>
            </tr>
            <tr v-for="item in selectedButtons" :key="item.button">
              <td>
                {{ item.buttonName }}
              </td>
              <td class="text-center vertical-align-middle">
                <button
                  type="button"
                  class="btn btn-danger btn-sm"
                  @click="buttonRemove(item)"
                >
                  {{
                    $t(
                      "ViewFilters.Remove",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <table
          class="table table-bordered table-buttons mb-0 mt-3"
          v-if="buttonLength() > 0"
        >
          <thead>
            <tr class="bg-warning bg-gradient">
              <td
                :colspan="buttonLength()"
                class="text-center fw-bold bg-gradient"
              >
                {{
                  $t(
                    "ViewFilters.Preview",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </td>
            </tr>
            <tr>
              <th v-if="viewFilterData.isDetailButtonActive" class="unsortable">
                <div
                  class="text-center d-flex justify-content-center align-items-center"
                >
                  <i class="fas fa-external-link-alt text-primary fa-2x"></i>
                </div>
                <div class="text-center">
                  <p class="mt-1 mb-1 text-trasform-capitalize fw-bold">
                    {{
                      $t(
                        "ViewFilters.Detail",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </p>
                </div>
              </th>
              <!-- <th v-if="viewFilterData.isActiveButtonActive" class="unsortable">
                <div
                  class="text-center d-flex justify-content-center align-items-center"
                >
                  <i class="fas fa-check-square text-success fa-2x"></i>
                </div>
                <div class="text-center">
                  <p
                    class="mt-1 mb-1 text-trasform-capitalize fw-bold"
                  >
                    {{
                      $t(
                        "ViewFilters.Active",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </p>
                </div>
              </th> -->
              <th v-if="viewFilterData.isDeleteButtonActive" class="unsortable">
                <div
                  class="text-center d-flex justify-content-center align-items-center"
                >
                  <i class="fas fa-trash-alt text-danger fa-2x"></i>
                </div>
                <div class="text-center">
                  <p class="mt-1 mb-1 text-trasform-capitalize fw-bold">
                    {{
                      $t(
                        "ViewFilters.Delete",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </p>
                </div>
              </th>
              <th v-if="viewFilterData.isEditButtonActive" class="unsortable">
                <div
                  class="text-center d-flex justify-content-center align-items-center"
                >
                  <i class="fas fa-edit text-warning fa-2x"></i>
                </div>
                <div class="text-center">
                  <p class="mt-1 mb-1 text-trasform-capitalize fw-bold">
                    {{
                      $t(
                        "ViewFilters.Edit",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </p>
                </div>
              </th>
              <th
                v-for="item in selectedButtons"
                :key="item.key"
                :data-id="item.button"
              >
                <div>
                  <div
                    class="text-center d-flex justify-content-center align-items-center"
                  >
                    <i
                      v-if="item.buttonIconColor"
                      :class="item.buttonIcon"
                      class="fa-2x"
                      :style="{ color: item.buttonIconColor }"
                    ></i>
                    <i :class="item.buttonIcon" class="fa-2x" v-else></i>
                  </div>
                  <div class="text-center">
                    <p class="mt-1 mb-1 text-trasform-capitalize">
                      {{ item.buttonName }}
                    </p>
                  </div>
                </div>
              </th>
            </tr>
          </thead>
        </table>
      </div>
    </div>
    <ActionButtons
      v-if="$isMobile()"
      classes="mt-3"
      @methodSave="onSubmit('btn-save')"
      :isNewRecord="false"
    />
  </form>
  <ColumnDesigner
    ref="columnDesigner"
    :recordDetail="viewFilterData"
    :fields="viewFilterViewableFields"
  />
</template>
<script>
import $ from "jquery";
import ColumnDesigner from "@/components/custom/column-designer/ColumnDesignerModal.vue";

export default {
  name: "CustomObjectEditViewFilter",
  components: {
    ColumnDesigner,
  },
  data() {
    return {
      getViewFilterRequestFinished: false,
      isSavedData: false,
      queryBuilderIsVisible: false,
      selectedObjectRelation: [],
      submitButtonHtml: String.format(
        '<span class="loading-block"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span> {0}</span></span>',
        this.$t(
          "Buttons.PleaseWait",
          {},
          { locale: this.$store.state.activeLang }
        )
      ),
      disabledButtonClasses: ".btn-save, .btn-save-and-new, .btn-clear",
      errors: [],
      viewFilterData: {},
      columnTabActive: false,

      viewFilterViewableFields: [],
      selectedFields: [],

      selectedOrderFields: [],
      orderFields: [],
      orderField: "",
      orderFieldText: "",
      orderType: "",

      selectedButtons: [],
      button: "",
      buttonName: "",
      buttons: [],
      allButtons: [],

      multipleSelectValueReset: false,
      selectButtonReset: false,
      hiddenFields: [],

      aggregateTypes: [
        {
          key: "1",
          value: this.$t(
            "ViewFilters.Avarage",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "2",
          value: this.$t(
            "ViewFilters.Count",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "3",
          value: this.$t(
            "ViewFilters.Max",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "4",
          value: this.$t(
            "ViewFilters.Min",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "5",
          value: this.$t(
            "ViewFilters.Sum",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      selectedAggregateFields: [],
      aggregateFields: [],
      aggregateField: "",
      aggregateFieldText: "",
      aggregateType: "",
      aggregateTypeText: "",
      multipleSelectAggregateTypeReset: false,
      multipleSelectAggregateFieldValueReset: false,

      query: "",
      listHiddenFieldPublicIds: [],
      listHiddenWidths: [],
      listHiddenAligns: [],
      listHiddenDisableds: [],
      listPublicIds: [],
      listWidths: [],
      listAligns: [],
      listDisableds: [],
      listRequireds: [],
      criteriaJson: null,
    };
  },
  beforeRouteLeave(to, from, next) {
    if (!this.isSavedData && this.$root.checkUnSavedData(this.viewFilterData)) {
      const answer = window.confirm(
        this.$t(
          "BaseModelFields.AlertUnSavedData",
          {},
          { locale: this.$store.state.activeLang }
        )
      );
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  beforeRouteUpdate(to, from, next) {
    if (!this.isSavedData && this.$root.checkUnSavedData(this.viewFilterData)) {
      const answer = window.confirm(
        this.$t(
          "BaseModelFields.AlertUnSavedData",
          {},
          { locale: this.$store.state.activeLang }
        )
      );
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  methods: {
    onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    },
    buttonLength(isSystemLength) {
      var buttonLength = this.selectedButtons.length,
        systemButtonLength = 0;

      if (this.viewFilterData.isDeleteButtonActive) systemButtonLength++;
      if (this.viewFilterData.isDetailButtonActive) systemButtonLength++;
      // if (this.viewFilterData.isActiveButtonActive) systemButtonLength++;
      if (this.viewFilterData.isEditButtonActive) systemButtonLength++;

      if (isSystemLength) {
        return systemButtonLength;
      }

      return buttonLength + systemButtonLength;
    },
    onChangeRelationDetail: function (selected) {
      this.viewFilterData.lookupRelationPublicId = selected.key;
    },
    getButtons() {
      var self = this;
      this.$prodGatewayAxios
        .get(
          `/Lcdp-SummaryActionButtons?customObjectPublicId=${this.$route.params.customObjectId}`
        )
        .then((response) => {
          response.data.items.forEach(function (f) {
            if (self.selectedButtons.find((d) => d.button == f.key) == null) {
              self.buttons.push(f);
            }
          });
        })
        .catch(function (error) {
          //alert(error);
        });
    },
    onChangeForButton(item) {
      this.button = item.key;
      this.buttonName = item.value;
    },
    buttonAdd() {
      if (
        String.isNullOrWhiteSpace(this.button) ||
        String.isNullOrWhiteSpace(this.buttonName)
      ) {
        return;
      }

      this.selectButtonReset = !this.selectButtonReset;
      var self = this;
      this.buttons = this.buttons.filter(function (f) {
        return f.key !== self.button;
      });

      var buttonProperties = this.allButtons.find(
        (f) => f.publicId == this.button
      );
      this.selectedButtons.push({
        button: this.button,
        buttonName: this.buttonName,
        buttonIcon:
          buttonProperties.icon != null
            ? buttonProperties.icon
            : "bi bi-braces",
        buttonIconColor: buttonProperties.iconColor,
      });

      this.buttonSortableInit();
      this.buttonLength();
    },
    buttonSortableInit() {
      var self = this;
      $(".table-buttons thead tr:last")
        .sortable({
          axis: "x",
          cursor: "grab",
          placeholder: "ui-state-highlight",
          helper: "clone",
          stop: function (event, ui) {
            if (ui.item.first().index() < self.buttonLength(true)) {
              $(this).sortable("cancel");
            }
          },
          cancel: ".unsortable",
        })
        .disableSelection();
    },
    buttonRemove(button) {
      this.selectedButtons = this.selectedButtons.filter(function (f) {
        return f.button !== button.button;
      });

      this.buttons.push({
        key: button.button,
        value: button.buttonName,
      });

      this.buttonLength();
    },
    onChangeForOrderField(item) {
      this.orderField = item.key;
      this.orderFieldText = item.value;
    },
    onChangeForAggregateField(item) {
      this.aggregateField = item.key;
      this.aggregateFieldText = item.value;
    },
    onChangeForAggregateType(item) {
      this.aggregateType = item.key;
      this.aggregateTypeText = item.value;
    },
    totalFieldAdd() {
      if (
        String.isNullOrWhiteSpace(this.aggregateField) ||
        String.isNullOrWhiteSpace(this.aggregateType)
      ) {
        return;
      }

      this.multipleSelectAggregateTypeReset =
        !this.multipleSelectAggregateTypeReset;
      this.multipleSelectAggregateFieldValueReset =
        !this.multipleSelectAggregateFieldValueReset;
      var self = this;
      this.aggregateFields = this.aggregateFields.filter(function (f) {
        return f.key !== self.aggregateField;
      });

      this.selectedAggregateFields.push({
        field: this.aggregateField,
        fieldText: this.aggregateFieldText,
        aggregateType: this.aggregateType,
        aggregateTypeText: this.aggregateTypeText,
      });
    },
    orderFieldAdd() {
      if (
        String.isNullOrWhiteSpace(this.orderField) ||
        String.isNullOrWhiteSpace(this.orderType) ||
        this.selectedOrderFields.length >= 3
      ) {
        return;
      }

      this.multipleSelectValueReset = !this.multipleSelectValueReset;
      var self = this;
      this.orderFields = this.orderFields.filter(function (f) {
        return f.key !== self.orderField;
      });

      this.selectedOrderFields.push({
        field: this.orderField,
        fieldText: this.orderFieldText,
        orderType: this.orderType,
        orderTypeText: Boolean.toBool(this.orderType) ? "asc" : "desc",
        orderTypeBT: Boolean.toBool(this.orderType),
      });
      this.orderType = "";
    },
    orderFieldRemove(field) {
      this.selectedOrderFields = this.selectedOrderFields.filter(function (f) {
        return f.field !== field.field;
      });

      this.orderFields.push({
        key: field.field,
        value: field.fieldText,
      });
    },
    aggregateFieldRemove(field) {
      this.selectedAggregateFields = this.selectedAggregateFields.filter(
        function (f) {
          return f.field !== field.field;
        }
      );

      this.aggregateFields.push({
        key: field.field,
        value: field.fieldText,
      });
    },
    tabValidation($event) {
      var form = $(".form-viewfilter-edit");
      form.addClass("was-validated");
      if (form[0].checkValidity()) {
        form.removeClass("was-validated");
        this.columnTabActive = $event.target.id.includes("column");
      }
    },
    getViewFilterViewableFields() {
      this.$prodGatewayAxios
        .get(
          `/Lcdp-SummaryViewFilterViewableFields?coId=${this.$route.params.customObjectId}&pageNumber=1&pageSize=400`
        )
        .then((response) => {
          this.viewFilterViewableFields = response.data.items;
          this.orderFields = response.data.items;
          this.getViewFilterDetail();
        })
        .catch(function (error) {
          //alert(error);
        });
    },
    onSubmit(buttonName) {
      var form = $(".form-viewfilter-edit");
      form.addClass("was-validated");
      if (
        !form[0].checkValidity() ||
        form.find(".ms-invalid:visible").length > 0
      ) {
        return;
      }

      var columnDesigner = this.$refs.columnDesigner;
      if (!columnDesigner) return;

      if (columnDesigner.visibleFields.length == 0) {
        $("#pills-columns-tab").trigger("click");
        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 100);
        this.errors.push(
          this.$t(
            "ViewFilters.PleaseAddTheFieldToDisplayMessage",
            {},
            { locale: this.$store.state.activeLang }
          )
        );
        setTimeout(() => (this.errors = []), 3000);
        return;
      }

      var button = $(String.format(".{0}", buttonName)),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block"),
        disabledButtons = $(this.disabledButtonClasses);

      disabledButtons.prop("disabled", true);
      firstSpan.hide();
      if (loadingBlock.length === 0) {
        firstSpan.after(this.submitButtonHtml);
        loadingBlock = button.find(".loading-block");
      } else {
        loadingBlock.show();
      }

      this.viewFilterData.criterias = this.$refs.sxQuerybuilder.criterias;
      this.viewFilterData.criteriaExpression =
        this.$refs.sxQuerybuilder.expression;

      this.viewFilterData.visibleFieldPublicIds = [];
      this.viewFilterData.visibleFieldAligns = [];
      this.viewFilterData.visibleFieldDisableds = [];
      this.viewFilterData.visibleFieldRequireds = [];
      this.viewFilterData.visibleFieldWidths = [];
      this.viewFilterData.visibleFieldWidthLockeds = [];

      this.viewFilterData.hiddenFieldPublicIds = [];
      this.viewFilterData.hiddenFieldAligns = [];
      this.viewFilterData.hiddenFieldDisableds = [];
      this.viewFilterData.hiddenFieldRequireds = [];
      this.viewFilterData.hiddenFieldWidths = [];

      columnDesigner.visibleFields.forEach((item) => {
        this.viewFilterData.visibleFieldPublicIds.push(item.key);
        this.viewFilterData.visibleFieldAligns.push(item.align);
        this.viewFilterData.visibleFieldDisableds.push(item.disabled);
        this.viewFilterData.visibleFieldRequireds.push(item.required);
        this.viewFilterData.visibleFieldWidths.push(item.width);
        this.viewFilterData.visibleFieldWidthLockeds.push(item.widthLocked);
      });

      columnDesigner.hiddenFields.forEach((item) => {
        this.viewFilterData.hiddenFieldPublicIds.push(item.key);
        this.viewFilterData.hiddenFieldAligns.push(item.align);
        this.viewFilterData.hiddenFieldDisableds.push(item.disabled);
        this.viewFilterData.hiddenFieldRequireds.push(item.required);
        this.viewFilterData.hiddenFieldWidths.push(item.width);
      });

      this.viewFilterData.clientWidth = parseInt(columnDesigner.clientWidth);

      this.viewFilterData.aggregationFieldPublicIds =
        this.selectedAggregateFields.map(function (m) {
          return m.field;
        });
      this.viewFilterData.aggregationFieldTypeIds =
        this.selectedAggregateFields.map(function (m) {
          return m.aggregateType;
        });
      this.viewFilterData.aggregationFieldDisableds =
        this.selectedAggregateFields.map(function () {
          return false;
        });
      this.viewFilterData.orderingFieldPublicId = this.selectedOrderFields
        .map(function (v) {
          return v.field;
        })
        .join(this.$systemSeparator);
      this.viewFilterData.orderingFieldName = this.selectedOrderFields
        .map(function (v) {
          return v.fieldText;
        })
        .join(this.$systemSeparator);
      this.viewFilterData.ordering = this.selectedOrderFields
        .map(function (v) {
          return v.orderTypeBT;
        })
        .join(this.$systemSeparator);

      var buttonOrders = [],
        buttonIds = [];
      $(".table-buttons tr th:not(.unsortable)").each(function (i, v) {
        buttonIds.push($(v).data("id"));
        buttonOrders.push(++i);
      });
      this.viewFilterData.buttonIds = buttonIds;
      this.viewFilterData.buttonOrders = buttonOrders;

      this.errors = [];
      this.$prodGatewayAxios
        .post("/Lcdp-ViewFilterUpdate", { ...this.viewFilterData })
        .then((r) => {
          var response = r.data;
          if (response.isOk) {
            this.isSavedData = true;
            this.$router.push(
              "/CustomObject/ViewFilters/" + this.$route.params.customObjectId
            );
          } else {
            firstSpan.show();
            loadingBlock.hide();
            disabledButtons.prop("disabled", false);
            this.errors.push(response.msg);
          }
        })
        .catch((r) => {
          firstSpan.show();
          loadingBlock.hide();
          disabledButtons.prop("disabled", false);
          this.errors.push(r);
        });
    },
    getViewFilterDetail() {
      this.queryBuilderIsVisible = false;
      this.getViewFilterRequestFinished = false;
      this.$prodGatewayAxios
        .get(
          String.format(
            "/Lcdp-ViewFilterGet?id={0}&coId={1}",
            this.$route.params.viewFilterId,
            this.$route.params.customObjectId
          )
        )
        .then((response) => {
          this.getViewFilterRequestFinished = true;
          this.viewFilterData = response.data;
          if (response.data.isOk) {
            Object.keys(response.data).forEach(function (key) {
              response.data["old_" + key] = response.data[key];
            });

            this.queryBuilderIsVisible = true;

            if (this.viewFilterData.buttons.length > 0) {
              this.viewFilterData.buttons.forEach((v) => {
                this.selectedButtons.push({
                  button: v.publicId,
                  buttonName: v.name,
                  buttonIcon: !String.isNullOrWhiteSpace(v.icon)
                    ? v.icon
                    : "bi bi-braces",
                  buttonIconColor: v.iconColor,
                });
              });
            }

            this.$prodGatewayAxios
              .get(
                String.format(
                  "/Lcdp-ButtonList?customObjectPublicId={0}",
                  this.$route.params.customObjectId
                )
              )
              .then((response) => {
                this.allButtons = response.data.items;
                this.getButtons();
              })
              .catch(function (error) {
                //alert(error);
              });

            let orjListWidths = [];
            this.viewFilterData.visibleFields.forEach((v) => {
              //percentage to pixel
              var width = v.width;
              orjListWidths.push(v.width);
              this.listPublicIds.push(v.publicId);
              this.listWidths.push(width);
              this.listAligns.push(v.align);
              this.listDisableds.push(v.disabled);
              this.listRequireds.push(v.required);
              this.selectedFields.push({
                key: v.publicId,
                value: v.fieldModel.name,
                disabled: v.disabled,
                align: v.align,
                width: width,
                required: v.required,
              });

              this.aggregateFields.push({
                key: v.publicId,
                value: v.fieldModel.name,
              });

              this.viewFilterViewableFields =
                this.viewFilterViewableFields.filter(function (f) {
                  return f.key !== v.publicId;
                });
            });

            this.viewFilterData.hiddenFields.forEach((v) => {
              this.listHiddenFieldPublicIds.push(v.publicId);
              this.listHiddenWidths.push(v.width);
              this.listHiddenAligns.push(v.align);
              this.listHiddenDisableds.push(v.disabled);
              this.hiddenFields.push({
                key: v.publicId,
                value: v.fieldModel.name,
                disabled: v.disabled,
              });

              this.aggregateFields.push({
                key: v.publicId,
                value: v.fieldModel.name,
              });

              this.viewFilterViewableFields =
                this.viewFilterViewableFields.filter(function (f) {
                  return f.key !== v.publicId;
                });
            });

            this.viewFilterData.visibleFieldWidths = orjListWidths;

            this.viewFilterData.aggregationFields.forEach((v) => {
              this.selectedAggregateFields.push({
                field: v.publicId,
                fieldText: v.fieldModel.name,
                aggregateType: v.aggregateFunctionId,
                aggregateTypeText: v.aggregateFunction,
              });
              this.aggregateFields = this.aggregateFields.filter(function (f) {
                return f.key !== v.publicId.trim();
              });
            });
            if (
              !String.isNullOrWhiteSpace(
                this.viewFilterData.orderingFieldPublicId
              ) &&
              !String.isNullOrWhiteSpace(this.viewFilterData.ordering) &&
              !String.isNullOrWhiteSpace(this.viewFilterData.orderingFieldName)
            ) {
              var orderingList = this.viewFilterData.ordering.split(
                this.$systemSeparator
              );
              var orderingFieldPublicIdList =
                this.viewFilterData.orderingFieldPublicId.split(
                  this.$systemSeparator
                );
              var orderingFieldNameList =
                this.viewFilterData.orderingFieldName.split(
                  this.$systemSeparator
                );
              for (
                let index = 0;
                index < orderingFieldPublicIdList.length;
                index++
              ) {
                const ordering = orderingList[index];
                const orderingFieldName = orderingFieldNameList[index];
                const orderingFieldPublicId = orderingFieldPublicIdList[index];
                this.selectedOrderFields.push({
                  field: orderingFieldPublicId.trim(),
                  fieldText: orderingFieldName,
                  orderType: Boolean.toBool(ordering),
                  orderTypeText: Boolean.toBool(ordering) ? "asc" : "desc",
                  orderTypeBT: Boolean.toBool(ordering),
                });
                this.orderFields = this.orderFields.filter(function (f) {
                  return f.key !== orderingFieldPublicId.trim();
                });
              }
            }

            if (
              !String.isNullOrWhiteSpace(
                this.viewFilterData.lookupRelationPublicId
              ) &&
              !String.isNullOrWhiteSpace(this.viewFilterData.lookupRelationName)
            ) {
              this.selectedObjectRelation.push({
                key: this.viewFilterData.lookupRelationPublicId,
                value: this.viewFilterData.lookupRelationName,
              });
            }
          } else {
            this.errors.push(response.data.msg);
          }
        })
        .catch(function (error) {
          //alert(error);
        });
    },
    checkButtonTableSortableRows() {
      var self = this;
      (function loop() {
        setTimeout(function () {
          if ($(String.format(".table-buttons thead tr:last")).length > 0) {
            self.buttonSortableInit();
            return;
          }
          loop();
        }, 50);
      })();
    },
  },
  mounted() {
    this.getViewFilterViewableFields();
    this.checkButtonTableSortableRows();
  },
};
</script>
